<template>
  <div class="page-container">
    <div class="page-content">
      <progress-step></progress-step>
      <v-card flat>
        <v-card-text>
          <div class="bold-f-s">For authentication purpose, what is the last 4 digits of your Social Security Number?</div>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="3" sm="3" md="3">
              <v-text-field class="f-s-80" max-height="auto" v-model.trim.number="input0" ref="input0"
                            @keydown.8="deleteValue('input0','input0')"
                            hide-details placeholder="" v-mask='mask' algin="center"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field class="f-s-80" v-model.trim.number="input1" ref="input1"
                            @keydown.8="deleteValue('input1','input0')"
                            hide-details placeholder="" v-mask='mask' algin="center"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field class="f-s-80" v-model.trim.number="input2" ref="input2"
                            @keydown.8="deleteValue('input2','input1')"
                            hide-details placeholder="" v-mask='mask' algin="center"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field class="f-s-80" v-model.trim.number="input3" ref="input3"
                            @keydown.8="deleteValue('input3','input2')"
                            hide-details placeholder="" v-mask='mask' algin="center"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div class="page-action">
      <v-btn
        large
        block
        color="primary"
        value="verify"
        @click="verify">NEXT
      </v-btn>
    </div>
    <v-snackbar
      color="error"
      v-model="snackbar.display"
      multi-line
    >
      {{snackbar.message}}
      <template v-if="snackbar.color !== 'success'" v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.display = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
          <v-overlay
            absolute
            :value="overlay"
          >
            <v-icon class="mdi mdi-spin mdi-loading" large></v-icon>
          </v-overlay>
  </div>
</template>

<script>
import { Tool, DataType } from '@/js/core'
const tool = new Tool()
export default {
  data () {
    return {
      mask: '#',
      input1: '',
      input2: '',
      input3: '',
      input0: '',
      ssn: '',
      overlay: true,
      snackbar: {
        message: '',
        color: '',
        action: '',
        display: false
      },
      progress: 2
    }
  },
  created () {
    const _this = this
    this.ssn = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL)).ssn
    _this.overlay = false
  },
  methods: {
    deleteValue (inputValue, previousItem) {
      if (this[inputValue].toString().length > 0) {
        this[inputValue] = ''
      } else {
        this.$nextTick(() => {
          this[inputValue] = ''
          this.$refs[previousItem].focus()
        })
      }
    },
    verify () {
      const number = '' + this.input0 + this.input1 + this.input2 + this.input3
      if (number === '4321') {
        const _this = this
        _this.$swal({
          type: 'success',
          text: 'VERIFICATION SUCCESSFUL!',
          focusCancel: true,
          showConfirmButton: true,
          confirmButtonText: 'NEXT'
        }).then((res) => {
          _this.$router.push('/authentication/profile')
        })
      } else {
        if (number.length < 4 || this.ssn.indexOf(number) === -1) {
          this.snackbar.message = 'The number entered does not agree with our record. Please correct it'
          this.snackbar.display = true
        } else {
          const _this = this
          this.snackbar.message = 'VERIFICATION SUCCESSFUL!'
          this.snackbar.color = 'success'
          this.snackbar.display = true
          setTimeout(function () {
            _this.$router.push('/authentication/profile')
          }, 1000)
        }
      }
    }
  },
  watch: {
    input0 (value) {
      if (tool.isNotEmpty(value)) {
        this.$refs.input1.focus()
      }
    },
    input1 (value) {
      if (tool.isNotEmpty(value)) {
        this.$refs.input2.focus()
      }
    },
    input2 (value) {
      if (tool.isNotEmpty(value)) {
        this.$refs.input3.focus()
      }
    }
  }
}
</script>

<style lang="css">
  input {
    text-align: center !important;
  }

  .v-input input {
    max-height: unset;
  }
</style>
